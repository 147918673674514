import { PageParams, ReviewBody } from "types";
import { privateAxios } from "./axios";

const API_URL_VIOLATIONS_SEARCH = "/video/search/violation";
const API_URL_VIOLATIONS_REVIEW = "/video/review/violation";


export interface violationsParams extends PageParams {}

export const fetchViolations = (params: violationsParams) => {
  return privateAxios.post(
    API_URL_VIOLATIONS_SEARCH,
    params
  );
};

//提交审核
export const reviewViolations = (body: ReviewBody) => {
  return privateAxios.post(API_URL_VIOLATIONS_REVIEW, body);
};
