import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import {
  GiftSearchType,
  RelationshipSearchType,
  fetchProfile,
  fetchProfileReports,
  checkUserSub,
  checkUserOrder,
  getUserViolationRecords,
} from "services";
import { getErrorMessage } from "utils";
import { CallsRespProps, callsRespQuery } from "./calls";
import { errorState } from "./error";
import { giftRecordsRespQuery } from "./gift";
import { pageSizeState } from "./pagination";
import { relationshipsRespQuery } from "./relationship";

export const profileRequestIdState = atom({
  key: "profile/requestId",
  default: dayjs().valueOf(),
});

const profileRespQuery = selectorFamily({
  key: "profile/resp",
  get:
    (id: number) =>
      async ({ get }) => {
        get(profileRequestIdState);

        try {
          const resp = await fetchProfile(id);
          return resp.data;
        } catch (error) {
          setRecoil(errorState, getErrorMessage(error));
        }
      },
});

export const profileQuery = selectorFamily({
  key: "profile",
  get:
    (id: number) =>
      ({ get }) => {
        const resp = get(profileRespQuery(id));
        return resp?.data;
      },
});

export const profileLikesPageState = atom({
  key: "profile/likes/page",
  default: 1,
});

export const profileLikesQuery = selectorFamily({
  key: "profile/likes",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.LIKED,
            uid,
            pageState: profileLikesPageState,
          })
        )?.data ?? [],
});

export const profileLikesCountQuery = selectorFamily({
  key: "profile/likes/count",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.LIKED,
            uid,
            pageState: profileLikesPageState,
          })
        )?.page?.total ?? 0,
});

export const profileFollowingPageState = atom({
  key: "profile/following/page",
  default: 1,
});

export const profileFollowingQuery = selectorFamily({
  key: "profile/following",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.FOLLOWING,
            uid,
            pageState: profileFollowingPageState,
          })
        )?.data ?? [],
});

export const profileFollowingCountQuery = selectorFamily({
  key: "profile/following/count",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.FOLLOWING,
            uid,
            pageState: profileFollowingPageState,
          })
        )?.page?.total ?? 0,
});

export const profileFollowersPageState = atom({
  key: "profile/followers/page",
  default: 1,
});

export const profileFollowersQuery = selectorFamily({
  key: "profile/follows",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.FOLLOWER,
            uid,
            pageState: profileFollowersPageState,
          })
        )?.data ?? [],
});

export const profileFollowersCountQuery = selectorFamily({
  key: "profile/follows/count",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.FOLLOWER,
            uid,
            pageState: profileFollowersPageState,
          })
        )?.page?.total ?? 0,
});

export const profileBlocksPageState = atom({
  key: "profile/blocks/page",
  default: 1,
});

export const profileBlocksQuery = selectorFamily({
  key: "profile/blocks",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.BLOCK,
            uid,
            pageState: profileBlocksPageState,
          })
        )?.data ?? [],
});

export const profileBlocksCountQuery = selectorFamily({
  key: "profile/blocks/count",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          relationshipsRespQuery({
            type: RelationshipSearchType.BLOCK,
            uid,
            pageState: profileBlocksPageState,
          })
        )?.page?.total ?? 0,
});

export const profileGiftsPageState = atom({
  key: "profile/gifts/page",
  default: 1,
});

export const profileGiftsQuery = selectorFamily({
  key: "profile/gifts",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          giftRecordsRespQuery({
            type: GiftSearchType.RECEIVED,
            uid,
            pageState: profileGiftsPageState,
          })
        )?.data ?? [],
});

export const profileGiftsCountQuery = selectorFamily({
  key: "profile/gifts/count",
  get:
    (uid: number) =>
      ({ get }) =>
        get(
          giftRecordsRespQuery({
            type: GiftSearchType.RECEIVED,
            uid,
            pageState: profileGiftsPageState,
          })
        )?.page?.total ?? 0,
});

export const profileReportsPageState = atom({
  key: "profileReports/page",
  default: 1,
});

const profileReportsRespQuery = selectorFamily({
  key: "profile/reports/resp",
  get:
    (uid: number) =>
      async ({ get }) => {
        const page = get(profileReportsPageState);
        const pageSize = get(pageSizeState);
        const type = 3; // 被举报记录

        try {
          const resp = await fetchProfileReports({ page, pageSize, type, uid });
          return resp.data;
        } catch (error) {
          setRecoil(errorState, getErrorMessage(error));
        }
      },
});

export const profileReportsQuery = selectorFamily({
  key: "profile/reports",
  get:
    (uid: number) =>
      ({ get }) => {
        const resp = get(profileReportsRespQuery(uid));
        return resp?.data ?? [];
      },
});

export const profileReportsCountQuery = selectorFamily({
  key: "profile/reports/count",
  get:
    (uid: number) =>
      ({ get }) => {
        const resp = get(profileReportsRespQuery(uid));
        return resp?.page?.total ?? 0;
      },
});

export const profileRandomCallsPageState = atom({
  key: "profile/random/calls",
  default: 1,
});

export const profileDirectCallsPageState = atom({
  key: "profile/direct/calls",
  default: 1,
});

export const profileCallsQuery = selectorFamily({
  key: "profile/calls",
  get:
    (props: CallsRespProps) =>
      ({ get }) =>
        get(callsRespQuery(props))?.data ?? [],
});

export const profileCallsCountQuery = selectorFamily({
  key: "profile/calls",
  get:
    (props: CallsRespProps) =>
      ({ get }) =>
        get(callsRespQuery(props))?.page?.total ?? 0,
});

//获取用户订阅数据
export const userSubSelectorFamily = selectorFamily({
  key: 'userSubSelectorFamily',
  get: (userId: number) => async () => {
    try {
      const response = await checkUserSub(userId); // 使用传入的 userId
      return response.data; // 假设接口返回的数据在 data 属性中
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const orderPage = atom({
  key: 'orderPage',
  default: 1
})

export const maxOrderPageState = selectorFamily({
  key: "max_pages",
  get:
    (total: number) =>
      async () => {
        return Math.ceil(total / 30);
      },
});
// 获取用户订单数据
export const userSubOrder = selectorFamily({
  key: 'userSubOrderSelectorFamily',
  get: (uid: number) => async ({ get }) => {
    try {
      const page = get(orderPage);
      const pageSize = 30;
      const response = await checkUserOrder(uid, page, pageSize)
      return response.data
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  }
})

//用户违规记录数据
//默认页
export const violationPage = atom({
  key: 'violation/page',
  default: 1
})
export const violationPageQuery = selectorFamily({
  key: 'violation/page/query',
  get: (uid: number) => ({ get }) => {
    const resp = get(userViolationRecords(uid))
    return resp?.page?.total ?? 0;
  }
})
//获取违规记录数据
export const userViolationRecords = selectorFamily({
  key: 'user/violation/records',
  get: (uid: number) => async ({ get }) => {
    try {
      const page = get(violationPage)
      const pageSize = 30
      const response = await getUserViolationRecords(uid, page, pageSize)
      return response.data
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  }
})