import {
  Avatar,
  DeviceStatus,
  Gender,
  MonthParams,
  PageParams,
  ProfileStatus,
  Resp,
  roleStatus,
  Timestamp,
} from "types";
import { privateAxios } from "./axios";

export interface BasicProfileInfo {
  id: number;
  firstName: string;
  avatar: Avatar;
}

export interface Profile extends BasicProfileInfo {
  role: roleStatus;
  gender?: Gender;
  age?: number;
  region: string;
  status: ProfileStatus;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  birthday:Timestamp;
  deviceStatus:DeviceStatus
}

const API_URL_PROFILES_SEARCH = "/user/search";
const API_URL_PROFILES_REVIEW = "/user/review";

export interface ProfilesParams extends PageParams, MonthParams {
  imei?: string;
  id?: number;
  uuid?: string;
  firstName?: string;
  status?: ProfileStatus[];
}

export type ProfilesResp = Resp<Profile[]>;

export const fetchProfiles = (params: ProfilesParams) => {
  return privateAxios.post<ProfilesResp>(API_URL_PROFILES_SEARCH, params);
};

export type ReviewProfilesBody = {};

export const reviewProfiles = (body: ReviewProfilesBody) => {
  return privateAxios.post(API_URL_PROFILES_REVIEW, body);
};
