import { Avatar, HStack, Stack, Text } from "@chakra-ui/react";
import _ from "lodash";
import { Profile } from "services";
import { Gender } from "types";
import { ProfileId } from "./ProfileId";
import { ProfileStatusBadge } from "./badges/ProfileStatusBadge";
import { useState } from "react";
import ImagePreview from "./tools/PreviewMode";
import { ProfileRoleBadge } from "./badges";

type ProfileBasicInfoProps = {
  profile: Profile;
};

export function ProfileBasicInfo({ profile }: ProfileBasicInfoProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(true); // 打开模态框
  };

  const handleClose = () => {
    setIsOpen(false); // 关闭模态框
  };
  return (
    <ProfileId uid={profile.id}>
      <HStack key={profile.id}>
        <Avatar
          src={profile.avatar.url}
          name={profile.firstName}
          onClick={handleClick} // 点击打开模态框
        />
        <Stack spacing={0}>
          <HStack>
            <Text
              fontSize={"18"}
              fontWeight={"medium"}>
              {profile.firstName}
            </Text>
            <ProfileStatusBadge status={profile.status} />
            <ProfileRoleBadge status={profile.role}/>
          </HStack>
          <Text fontSize={12}>
            {_.compact([
              profile.age,
              profile.gender != null && Gender[profile.gender],
              profile.region,
            ]).join(", ")}
          </Text>
        </Stack>
      </HStack>
      {/* 模态框 */}
      {isOpen && <ImagePreview image={profile.avatar.url} onClose={handleClose} />}
    </ProfileId>
  );
}
