import { Box, Spacer, Stack } from "@chakra-ui/react"
import { MainFooter, MainHeader, Pagination, SubmitButton, ViolationsRoleFilter,ViolationsStatusFilter } from "components"
import { falseViolationsIdsState, violationsCountQuery, violationsPageState, violationsRequestIdState, violationsToSubmitQuery } from "state/violations"
import { PhotosWarp } from "./Photos"
import { violationsQuery } from "state"
import { reviewViolations } from "services"
import { Suspense } from "react"

export function Violations() {
    return (
        <Stack w="100%" h='100%'>
            <MainHeader totalState={violationsCountQuery}>
                <ViolationsRoleFilter />
                <ViolationsStatusFilter />
            </MainHeader>
            <Box px='16px'>
                <Suspense>
                    <PhotosWarp
                        state={violationsQuery}
                        type="violation"
                        rejectedPhotoIdsState={falseViolationsIdsState}
                    />
                </Suspense>
            </Box>
            <Spacer />
            <MainFooter>
                <Pagination
                    state={violationsPageState}
                    totalState={violationsCountQuery}
                />
                <Spacer />
                <SubmitButton
                    state={violationsToSubmitQuery}
                    requestIdState={violationsRequestIdState}
                    rejectedIdsState={falseViolationsIdsState}
                    api={reviewViolations}
                />
            </MainFooter>
        </Stack>
    )
}