import { number } from "@recoiljs/refine";
import dayjs from "dayjs";
import { atom } from "recoil";
import { syncEffect } from "recoil-sync";

export const monthState = atom<number>({
  key: "month",
  default: +dayjs().format("YYYYMM"),
  effects: [syncEffect({ refine: number() })],
});

export const mediaStatusOptionState = atom<number>({
  key: "media_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const bioStatusOptionState = atom<number>({
  key: "bio_status",
  default: 0,
  effects: [syncEffect({ refine: number() })],
});

export const violationsOptionState = atom<number>({
  key: "violations_status",
  default: 0,
  effects:[syncEffect({refine: number()})]
})

export const violationsRoleState = atom<number>({
  key: "violations_role",
  default: -1,
  effects:[syncEffect({refine: number()})]
})
