export const ROUTE_URI_LOGIN = "/login";
export const ROUTE_URI_DASHBOARD = "/dashboard";
export const ROUTE_URI_PHOTOS = "/photos";
export const ROUTE_URI_STORIES = "/stories";
export const ROUTE_URI_BIOS = "/bios";
export const ROUTE_URI_REPORTS = "/reports";
export const ROUTE_URI_PROFILES = "/profiles";
export const ROUTE_URI_DEVICES = "/devices";
export const ROUTE_URI_CALLS = "/calls";
export const ROUTE_URI_RELATIONSHIPS = "/relationships";
export const ROUTE_URI_GIFTS = "/gifts";
export const ROUTE_URI_RECEIPTS = "/receipts";
export const ROUTE_URI_TRANSACTIONS = "/transactions";
export const ROUTE_URI_MANAGERS = "/managers";
export const ROUTE_URI_SECRETS = "/secrets";
export const ROUTE_URI_CAPTCHAS = "/captchas";
export const ROUTE_URI_SUBSCRIPTIONS = "/subscriptions"
export const ROUTE_URI_TRANSFER = "/transfers"
export const ROUTE_URI_NOTIFICATION = "/notifications"
export const ROUTE_URI_VIOLATIONS = "/violations"
