import {
  monthState,
  profileStatuses,
  profileStatusState,
  searchIMEIState,
  searchUIDState,
  searchUUIDState,
} from "components";
import { atom, selector } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchProfiles, ProfilesParams } from "services";
import { ProfileStatus } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";
import { errorState } from "./error";
import { pageSizeState } from "./pagination";

export const profilesPageState = atom({
  key: "profiles/page",
  default: 1,
});

export const profilesRespQuery = selector({
  key: "profiles/resp",
  get: async ({ get }) => {
    const page = get(profilesPageState);
    const pageSize = get(pageSizeState);

    const imei = get(searchIMEIState)
    const uid = get(searchUIDState);
    const uuid = get(searchUUIDState);
    const status = profileStatuses[get(profileStatusState)];

    const params: ProfilesParams = {
      page,
      pageSize,
    };

    if (!uid && !uuid && !imei) {
      if (status === ProfileStatus.All) {
        params.status = [
          ProfileStatus.Approved,
          ProfileStatus.Pending,
          ProfileStatus.Banned,
          ProfileStatus.Underage,
          ProfileStatus.Deleted,
        ];
      } else {
        params.status = [status];
      }

      const month = get(monthState);
      const searchTimestamps = getSearchTimestampsFrom(month);

      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    } else {
      params.imei= imei
      params.id = uid ? + uid : undefined;
      params.uuid = uuid;
    }

    try {
      const resp = await fetchProfiles(params);
      return resp.data;
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const profilesQuery = selector({
  key: "profiles",
  get: ({ get }) => {
    return get(profilesRespQuery)?.data ?? [];
  },
});

export const profilesCountQuery = selector({
  key: "profiles/count",
  get: ({ get }) => {
    return get(profilesRespQuery)?.page?.total ?? 0;
  },
});
