import {
  Box,
  Heading,
  Link,
  LinkProps,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { UserInfo } from "components/UserInfo";
import { callsTabState, relationshipsTabState, reportsTabState } from "pages";
import { Link as RouterLink, useMatch, useParams } from "react-router-dom";
import { useResetRecoilState } from "recoil";

import {
  ROUTE_URI_BIOS,
  ROUTE_URI_CALLS,
  ROUTE_URI_CAPTCHAS,
  ROUTE_URI_DEVICES,
  ROUTE_URI_GIFTS,
  ROUTE_URI_MANAGERS,
  ROUTE_URI_PHOTOS,
  ROUTE_URI_PROFILES,
  ROUTE_URI_RECEIPTS,
  ROUTE_URI_RELATIONSHIPS,
  ROUTE_URI_REPORTS,
  ROUTE_URI_SECRETS,
  ROUTE_URI_STORIES,
  ROUTE_URI_TRANSACTIONS,
  ROUTE_URI_SUBSCRIPTIONS,
  ROUTE_URI_TRANSFER,
  ROUTE_URI_NOTIFICATION,
  ROUTE_URI_VIOLATIONS
} from "utils/constants";
import {
  mediaStatusOptionState,
  monthState,
  profileStatusState,
  subStatusState,
} from "./filters";
import { searchIMEIState, searchOriginalID, searchUIDState } from "./search";

export function SideBar() {
  return (
    <Stack h="100%">
      <Heading
        position={"sticky"}
        top={0}
        p="4"
        size="md"
        textAlign="center"
        bg={"white"}
        zIndex={2}>
        Hiyak Console
      </Heading>
      <Box>
        {sidebarCategories.map((category) => (
          <Stack
            key={category.name}
            spacing={0}>
            <Text
              p={4}
              opacity={0.3}>
              {category.name}
            </Text>
            {category.routes.map((route) => (
              <SidebarLink
                key={route.name}
                route={route}
              />
            ))}
          </Stack>
        ))}
      </Box>
      <Spacer />
      <UserInfo />
    </Stack>
  );
}

export interface SidebarLinkProps extends LinkProps {
  route: HiyakRoute;
}

export function SidebarLink({ route, ...linkProps }: SidebarLinkProps) {
  // const resolved = useResolvedPath(route.uri);
  const params = useParams();
  const id = params["id"];

  const mathPath = id ? `${route.uri}/${id}` : route.uri;
  const match = useMatch(mathPath);

  // reset search params

  // common
  const resetMonth = useResetRecoilState(monthState);

  // search
  const resetUID = useResetRecoilState(searchUIDState);
  const resetIMEI = useResetRecoilState(searchIMEIState);
  const resetOriginalID = useResetRecoilState(searchOriginalID)

  // tabs
  const resetReportsTab = useResetRecoilState(reportsTabState);
  const restRelationshipsTab = useResetRecoilState(relationshipsTabState);
  const resetCallsTab = useResetRecoilState(callsTabState);

  // status
  const resetProfileStatus = useResetRecoilState(profileStatusState);
  const resetMediaStatus = useResetRecoilState(mediaStatusOptionState);
  const resetSourceStatus = useResetRecoilState(subStatusState)

  const handleClick = () => {
    // common
    resetMonth();
    // search
    resetUID();
    resetIMEI();
    resetOriginalID()
    // tabs
    resetReportsTab();
    restRelationshipsTab();
    resetCallsTab();

    // status
    resetProfileStatus();
    resetMediaStatus();
    resetSourceStatus()
  };

  return (
    <Link
      as={RouterLink}
      to={route.uri}
      p={4}
      bg={match ? "purple.100" : undefined}
      _hover={{
        bg: "purple.200",
      }}
      onClick={handleClick}
      {...linkProps}>
      {route.name}
    </Link>
  );
}

interface SidebarCategory {
  name: string;
  routes: HiyakRoute[];
}

interface HiyakRoute {
  name: string;
  uri: string;
  subRoutes?: HiyakRoute[];
}

const sidebarCategories: SidebarCategory[] = [
  {
    name: "CS",
    routes: [
      { name: "Photos", uri: ROUTE_URI_PHOTOS },
      { name: "Stories", uri: ROUTE_URI_STORIES },
      { name: "Bios", uri: ROUTE_URI_BIOS },
      { name: "Reports", uri: ROUTE_URI_REPORTS },
      {name:"Violations", uri: ROUTE_URI_VIOLATIONS}
    ],
  },
  {
    name: "Data",
    routes: [
      { name: "Profiles", uri: ROUTE_URI_PROFILES },
      { name: "Devices", uri: ROUTE_URI_DEVICES },
      { name: "Calls", uri: ROUTE_URI_CALLS },
      { name: "Relationships", uri: ROUTE_URI_RELATIONSHIPS },
      { name: "Gifts", uri: ROUTE_URI_GIFTS },
    ],
  },
  {
    name: "Purchase",
    routes: [
      { name: "Receipts", uri: ROUTE_URI_RECEIPTS },
      { name: "Transactions", uri: ROUTE_URI_TRANSACTIONS },
      {name: "Subscriptions", uri: ROUTE_URI_SUBSCRIPTIONS},
      {name:"Transfers", uri: ROUTE_URI_TRANSFER},
      {name:"Notifications",uri:ROUTE_URI_NOTIFICATION}
    ],
  },
  {
    name: "Manage",
    routes: [
      { name: "Managers", uri: ROUTE_URI_MANAGERS },
      { name: "App Secrets", uri: ROUTE_URI_SECRETS },
      { name: "Captchas", uri: ROUTE_URI_CAPTCHAS },
    ],
  },
];
