import {
  mediaStatusOptionState,
  mediaStatuses,
  monthState,
} from "components/filters";
import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";
import { setRecoil } from "recoil-nexus";
import { fetchPhotos } from "services/apis/media";
import { errorState, pageSizeState } from "state";
import { MediaStatus, ReviewBody, SearchParams } from "types";
import { getErrorMessage, getSearchTimestampsFrom } from "utils";

export const photosRequestIdState = atom({
  key: "photos/requestId",
  default: dayjs().valueOf(),
});

export const photosPageState = atom({
  key: "photos/page",
  default: 1,
});

export const photosRespQuery = selectorFamily({
  key: "photos/resp",
  get: (uid?: number | undefined) => async ({ get }) => {
    get(photosRequestIdState);

    const params: SearchParams<MediaStatus[]> = {
      page: get(photosPageState),
      pageSize: get(pageSizeState),
    };
    if (uid !== 0 ) {
      params.uid = uid
    }
    const mediaStatusValue = get(mediaStatusOptionState);
    params.status = [mediaStatuses[mediaStatusValue]];

    if (mediaStatusValue !== 0 && uid === 0) {
      const searchTimestamps = getSearchTimestampsFrom(get(monthState));
      params.createdStart = searchTimestamps.createdStart;
      params.createdEnd = searchTimestamps.createdEnd;
    }

    try {
      return await fetchPhotos(params);
    } catch (error) {
      setRecoil(errorState, getErrorMessage(error));
    }
  },
});

export const photosQuery = selectorFamily({
  key: "photos",
  get:
    (uid?: number | undefined) =>
      ({ get }) => {
        return get(photosRespQuery(uid))?.data.data ?? [];
      },
});

export const photosCountQuery = selectorFamily({
  key: "photos/count",
  get:
    (uid?: number | undefined) =>
      ({ get }) => {
        if (uid === undefined) {
          return 0; // 或者你可以根据需求返回其他默认值
        }
        return get(photosRespQuery(uid))?.data.page?.total ?? 0;
      },
});

export const rejectedPhotosIdsState = atom<number[]>({
  key: "photos/rejected",
  default: [],
});

export const isPhotoSelectedState = selectorFamily({
  key: "isPhotoSelected",
  get:
    (id: number) =>
      ({ get }) => {
        return get(rejectedPhotosIdsState).includes(id);
      },
});

export const photosToSubmitQuery = selectorFamily({
  key: "photos/submit",
  get:
    (uid?: number | undefined) =>
      ({ get }) => {
        const photos = get(photosQuery(uid));
        const rejectedIds = get(rejectedPhotosIdsState);

        const reviewBody: ReviewBody = {
          adoptIds: [],
          refuseIds: [],
        };

        photos.forEach((photo) => {
          console.log(photo);
          
          if (rejectedIds.includes(photo.id)) {
            reviewBody.refuseIds.push(photo.id);
          } else if (photo.status === MediaStatus.Pending) {
            reviewBody.adoptIds.push(photo.id);
          }
        });

        return reviewBody;
      },
});
