import { Badge } from "@chakra-ui/react";
import { roleStatus } from "types";

export function ProfileRoleBadge({ status }: { status: roleStatus }) {
  const getColorScheme = () => {
    switch (status) {
      case roleStatus.User:
        return "green";
      case roleStatus.Reviewer:
        return "red";
      case roleStatus.Bot:
        return "red";
      case roleStatus.Worker:
        return "red";
    }
  };
  return <Badge colorScheme={getColorScheme()}>{roleStatus[status]}</Badge>;
}
