import { ViolationStatus } from "types";
import { FilterSelector, SelectFilterOption } from "./filter-status.view";
import { violationsOptionState } from "./filter.state";

export const violationStatuses = [
    ViolationStatus.Pending,
    ViolationStatus.Approved,
    ViolationStatus.Rejected,
];

export function ViolationsStatusFilter() {
    return (
        <FilterSelector
            label="Status"
            statusState={violationsOptionState}
            options={violationStatuses.map((status, index) => {
                const option: SelectFilterOption = {
                    label: ViolationStatus[status],
                    value: index,
                };
                return option;
            })}
        />
    )
}